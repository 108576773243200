<template>
  <div
    class="container size-mobile bg-element-white p-0 min-height-second paket"
    style="font-family: Montserrat"
  >
    <div class="alert alert-primary mt-2" role="alert" @click="cara()" style="border: none !important;border-radius: 0px;margin-bottom:-12px !important;">
      <i class="fa fa-info-circle pr-2"></i><span style="font-size:12px;">Panduan <b>(Klik Disini)</b></span>
    </div>
    <v-dialog/>
    <alert-error ref="ae"/>
    <date-picker v-model="startDate" valueType="format"></date-picker>
    <div class="container mt-1" v-if="isLoading">
      <content-placeholders :rounded="true">
        <content-placeholders-text :lines="1" />
        <content-placeholders-img />
        <content-placeholders-text :lines="2" />
        <content-placeholders-text :lines="1" />
      </content-placeholders>
    </div>
    <div v-else>
      <!--     
    <div class="container" v-if="isLoading">
      <content-placeholders :rounded="true">
        <content-placeholders-img /> 
      </content-placeholders> 
      </div>-->
      
      <div v-if="filteredData.length > 0" class="table-responsive text-center" style="font-size: 12px;font-family:roboto">
        <table v-if="datas" class="table table-borderless table-striped table-hover mt-3">
          <thead>
            <tr>
              <th scope="col">Driver</th>
              <th scope="col">Pegawai</th>
              <th scope="col">Tujuan</th>
              <th scope="col">Keluar/Masuk</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="data in filteredData"
              :key="data.id" 
              @click="showModal(data)" 
              :class="{'tr-danger': !data.v_jam_masuk}" 
              >
              <td class="no_resi">{{data.nama_driver}}</td>
              <td class="pengirim">{{data.nama_pegawai}}</td>
              <td class="penerima">{{data.keperluan}}</td> 
              <td class="status">{{data.v_jam_keluar}} - {{data.v_jam_masuk}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="container" v-else>
        <div class="text-date d-flex bg-highlight justify-content-center bg-light rounded p-3"> Tidak ada kendaraan keluar ditanggal ini</div>
      </div>
      
      
      <!-- Modal  -->
      <modal v-show="isModalVisible" @close="closeModal" style="z-index: 99">
        <!-- Override Modal Header -->
        <template v-slot:header><b style="color: #2b80ac">Update Kendaraan Dinas</b></template>
        <!-- Override Modal body -->
        <template v-slot:body>
          <form id="edit-form" class="form-horizontal" method="POST" @submit.prevent="update(dataModal.id)" enctype="multipart/form-data"> 
            <fieldset>
              <legend class="legend-input">Nama Driver</legend>
              <input type="text" name="modal-input-pengirim" class="form-control" autocomplete="off" v-model="dataModal.nama_driver" required="required"/>
            </fieldset>
            <fieldset>
              <legend class="legend-input">Nama Pegawai</legend>
              <input type="text" name="modal-input-penerima" class="form-control" autocomplete="off" v-model="dataModal.nama_pegawai" required="required"/>
            </fieldset>
            <fieldset>
              <legend class="legend-input">Plat Nomor</legend>
              <input type="text" name="modal-input-resi" class="form-control" autocomplete="off" v-model="dataModal.no_polisi" required="required"/>
            </fieldset>
             <fieldset>
              <legend class="legend-input">Tujuan</legend>
              <textarea name="tujuan" class="form-control" autocomplete="off" v-model="dataModal.keperluan" required="required"></textarea>
            </fieldset>
            <div class="mb-1">
              <legend class="legend-input mt-3">Status Menginap</legend>
              <div class="d-flex mt-2">
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="menginap" value="1" v-model="dataModal.menginap"/>
                  <label class="form-check-label" for="status">Ya</label>
                </div>
                <div class="form-check ml-4">
                  <input class="form-check-input" type="radio" name="menginap" value="0" v-model="dataModal.menginap"/>
                  <label class="form-check-label" for="exampleRadios2">Tidak</label>
                </div>
              </div>
            </div>
            <div class="mb-1">
              <legend class="legend-input mt-3">Jenis Kendaraan</legend>
              <div class="d-flex mt-2">
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="status" value="2" v-model="dataModal.jenis_kendaraan"/>
                  <label class="form-check-label" for="status">Roda 2</label>
                </div>
                <div class="form-check ml-4">
                  <input class="form-check-input" type="radio" name="status" value="4" v-model="dataModal.jenis_kendaraan"/>
                  <label class="form-check-label" for="exampleRadios2">Roda 4</label>
                </div>
              </div>
            </div>
            <div class="d-flex">
              <fieldset class="mt-2" style="width:50%">
                <legend class="legend-input">Jam Keluar</legend> 
                <input type="time" v-model="dataModal.v_jam_keluar" class="form-control" autocomplete="off"  style="border:0 !important"/>
                <!-- <img style="position: absolute;right: 30px;top: 425px;width: 25px;" src="../../assets/img/clock.png"> -->
              </fieldset>
              <span style="width:20px;"></span>        
              <fieldset class="mt-2" style="width:50%">
                <legend class="legend-input">Jam Masuk</legend> 
                <input type="time" v-model="dataModal.v_jam_masuk" class="form-control" autocomplete="off"  style="border:0 !important"/>
                <!-- <img style="position: absolute;left:155px;top: 425px;width: 25px;" src="../../assets/img/clock.png"> -->
              </fieldset>
            </div>
            
            <button class="btn-blue mt-4" style="width:100%" :class="{'disable': isLoadingUpload}"  type="submit">
              <span class="d-flex align-items-center justify-content-center" v-if="isLoadingUpload">
                  <div class="spinner-border text-primary mr-3 spinner-border-sm" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                Sedang Upload...
              </span>
              <span v-else>Simpan</span>
            </button> 
            <span class="btn btn-danger mt-3" style="width:100%" @click="modalDelete(dataModal.id)" :class="{'disable': isSubmit}" type="submit">Hapus</span> 
          </form>
        </template>
      </modal>
      
      
    </div>
    <div class="d-flex">
      <router-link class="float" to="/kendaraan/add">
        <i class="fa fa-plus my-float"></i>
      </router-link>
      <span class="float" @click="load()" style="background-color:#e0e0e0;color:#000;right:90px !important">
        <i class="fa fa-refresh my-float"></i>
      </span>
    </div>
    <alert ref="c"/>
    
  </div>
</template>
 
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css"; 

// import $ from "jquery";
// import _ from "lodash";
import modal from "../../components/Modal.vue";
import Api from "../../Api";
import alertError from "../../components/AlertError.vue";
import alert from "../../components/Alert.vue";

export default {
  name: "kendaraanDinas",
  components: {
    DatePicker,
    modal,
    alertError,
    alert, 
  },
  data() {
    var currentdate = new Date(); 
    var datetime = currentdate.getFullYear() + "-"
            + ('0' + (currentdate.getMonth()+1)).slice(-2)  + "-" 
            + ('0' + (currentdate.getDate())).slice(-2)
    return {
      startDate:datetime,
      isLoading: true,
      datas: [],
      isLoadingUpload:false,
      total: 0,
      dataModal: [],
      isModalVisible: false,
      updatedContent: "",
      token: '',
      myData: '',
      isSubmit: false,
      dataFiltered: 0
    };
  },

  async mounted() {
    this.token = JSON.parse(localStorage.getItem('token'))
    this.myData = JSON.parse(localStorage.getItem('cred'))
    this.load();
    // console.log(JSON.parse(localStorage.getItem('cred')));
  },

  methods: {
    cara(){
      this.$refs.c.showAlert('Panduan','1. <b>Kolom berwarna merah</b> artinya, Jam Keluar belum dilengkapi, untuk melengkapinya, dengan klik kolom tamu yang akan di update, kemudian isi <b>jam keluar</b>,kemudian Simpan<br><br>2. <b>Jika data tidak update,</b> klik tombol <b><i class="fa fa-refresh"></i></b> Sebelah tombol <b><i class="fa fa-plus"></i></b><br>')
    },
    async load() {
      this.isLoading = true
      await Api.get("satpam/kendaraan/"+this.myData.id_tempat_kerja,{
        headers: {Authorization: 'Bearer ' + this.token}
      })
      .then((response) => {
          this.datas = response.data.data;
          // console.log(this.datas)
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.$refs.ae.showAlert()
        });
    },
    async update(id){
      this.isLoadingUpload = true
      const data = {
        user_id: this.myData.id,
        workplace_id: this.myData.id_tempat_kerja,
        nama_pegawai: this.dataModal.nama_pegawai,
        nama_driver: this.dataModal.nama_driver,
        keperluan: this.dataModal.keperluan,
        no_polisi: this.dataModal.no_polisi,
        jenis_kendaraan: this.dataModal.jenis_kendaraan,
        jam_keluar: this.dataModal.v_jam_keluar,
        jam_masuk: this.dataModal.v_jam_masuk,
        menginap: this.dataModal.menginap,
      }
      // console.log(data)
      await Api.put("satpam/kendaraan/"+id,data,{
        headers: {Authorization: 'Bearer ' + this.token}
      })
      .then(() => {
        this.closeModal()
        this.load()
        // console.log(response) 
      })
      .catch((error) => {

        console.log(error);
        this.$refs.ae.showAlert()
      });
      this.isLoadingUpload = false 
    }, 
    showModal(data) {
      // console.log(data)
      this.dataModal = data; 
      // this.updatedContent = data.pengirim
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    modalDelete(id){
      this.$modal.show('dialog', {
        title: 'Yakin Hapus Data kendaraan ?',
        text: 'Data yang telah dihapus tidak dapat dikembalikan',
        buttons: [
          {
            title: 'Tutup',
            handler: () => {
              this.$modal.hide('dialog')
              // this.$router.push(this.$route.query.redirect || '/')
            }
          }, 
          {
            title: 'Hapus',
            handler: () => {
              Api.delete("satpam/kendaraan/"+id,{
                headers: {Authorization: 'Bearer ' + this.token}
              })
              .then(() => {
                this.closeModal()
                this.$modal.hide('dialog')
                this.load()
              })
              .catch((error) => {

                console.log(error);
                this.$refs.ae.showAlert()
              });
              // this.$router.push(this.$route.query.redirect || '/')
            }
          }, 
        ]
      })
    }

  },
  // Untuk Search Paket
  computed: {
    filteredData() {
      var vm = this;
      var startDate = vm.startDate;
      // console.log(this.datas)
      return this.datas.filter(item => {
        var date = item.v_tgl_created;
        if (date == startDate) {
          // console.log(item.length)
          return date == startDate;
        }
      });
    }
  }
};
</script> 
<style >
  th,td{
    padding-left:3px;
    padding-right:3px;
  } 
</style>